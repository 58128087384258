import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101')
];

export const server_loads = [0,2,28,29,26,3,7,8,10,11,5,6,13,14,19,16,21];

export const dictionary = {
		"/(public)": [~96,[27]],
		"/(private)/create-account": [~87,[2,24]],
		"/(public)/(premium)/guides": [97,[27,28,29],[,,30]],
		"/(public)/(premium)/guides/[slug]": [~98,[27,28,29,31],[,,30]],
		"/(private)/help": [~88,[2,25]],
		"/(private)/home": [89,[2]],
		"/(public)/login": [101,[27,34]],
		"/(private)/profile": [~90,[2,26]],
		"/(private)/profile/accounts": [91,[2,26]],
		"/(private)/profile/authentication": [92,[2,26]],
		"/(private)/profile/invites": [93,[2,26]],
		"/(private)/profile/invites/[inviteId]": [~94,[2,26]],
		"/(private)/profile/lock": [~95,[2,26]],
		"/(public)/(premium)/tutorials": [~99,[27,28,32]],
		"/(public)/(premium)/tutorials/[slug]": [~100,[27,28,32,33]],
		"/(private)/[accountId]": [~35,[3],[4]],
		"/(private)/[accountId]/activity": [64,[3,17],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/activity/devices": [39,[3,7,8,9,10],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/activity/dns": [~40,[3,7,8,9,11],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/activity/dns/popular": [41,[3,7,8,9,11],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/activity/dns/recent": [42,[3,7,8,9,11],[4]],
		"/(private)/[accountId]/activity/history": [65,[3,17],[4]],
		"/(private)/[accountId]/(checkout)/checkout/stripe": [~36,[3,5,6],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy": [~43,[3,7,8,12],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect": [~44,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/android": [~45,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/android/app": [46,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/app/prevent-bypass": [47,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/ios": [~48,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/ios/app": [49,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/mac": [~50,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/mac/app": [51,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/router": [~52,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/windows": [~53,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/connect/windows/app": [54,[3,7,8,12,13],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/rules/[ruleId]": [~55,[3,7,8,12,14],[4]],
		"/(private)/[accountId]/(cloudflare)/(synced)/content-policy/rules/[ruleId]/history": [~56,[3,7,8,12,14],[4]],
		"/(private)/[accountId]/(checkout)/select-plan": [37,[3,5],[4]],
		"/(private)/[accountId]/settings": [66,[3,18],[4]],
		"/(private)/[accountId]/settings/billing": [~67,[3,18,19],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)": [~57,[3,7,15,16],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/agent": [~58,[3,7,15,16],[4]],
		"/(private)/[accountId]/settings/cloudflare/connect": [~68,[3,20],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/debug": [~59,[3,7,15,16],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/networks": [~60,[3,7,15,16],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/networks/location": [~61,[3,7,15,16],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/sync": [~63,[3,7,15],[4]],
		"/(private)/[accountId]/(cloudflare)/settings/cloudflare/(synced)/traffic": [~62,[3,7,15,16],[4]],
		"/(private)/[accountId]/settings/members": [~69,[3,18],[4]],
		"/(private)/[accountId]/setup": [~70,[3,21],[4]],
		"/(private)/[accountId]/setup/ios": [~71,[3,21],[4]],
		"/(private)/[accountId]/setup/ios/prevent-bypass": [72,[3,21],[4]],
		"/(private)/[accountId]/setup/ios/prevent-bypass/supervised": [73,[3,21],[4]],
		"/(private)/[accountId]/setup/mac/prevent-bypass": [74,[3,21],[4]],
		"/(private)/[accountId]/(checkout)/success/stripe": [~38,[3,5],[4]],
		"/(private)/[accountId]/tools": [75,[3,22],[4]],
		"/(private)/[accountId]/tools/ios": [76,[3,22],[4]],
		"/(private)/[accountId]/tools/ios/app-management": [~77,[3,22,23],[4]],
		"/(private)/[accountId]/tools/ios/app-management/allowlist": [78,[3,22,23],[4]],
		"/(private)/[accountId]/tools/ios/app-management/blocklist": [79,[3,22,23],[4]],
		"/(private)/[accountId]/tools/ios/config-generator/custom": [80,[3,22],[4]],
		"/(private)/[accountId]/tools/ios/config-presets": [81,[3,22],[4]],
		"/(private)/[accountId]/tools/mac": [82,[3,22],[4]],
		"/(private)/[accountId]/tools/mac/config-presets": [83,[3,22],[4]],
		"/(private)/[accountId]/tools/mac/content-policy-enforcer": [84,[3,22],[4]],
		"/(private)/[accountId]/tools/windows": [85,[3,22],[4]],
		"/(private)/[accountId]/tools/windows/content-policy-enforcer": [86,[3,22],[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';